/*
We can use following api calls and can change api name easily
*/
module.exports = {
  getCartData: "/getcartdata",
  saveCartData: "/savecartdata",
  orderHistory: "/orderhistory",
  archiveOrderHistory: "/archiveorderhistory",
  bulkOrderFile: "/bulkorderfile",
  bulkOrderHistory: "/bulkorderlisting",
  bulkorderunprocessed: "/bulkorderunprocessed",
  submittedOrder: "/submittedorder",
  getDivLocs: "/getdivlocs",
  news: "/news",
  helps: "/helps",
  states: "/states",
  productList: "/productlist",
  suggestedProducts: "/b2b/suggesteditems",
  productDetail: "/productdetail",
  programList: "/programlist",
  productNotifyMe: "/productNotifyMe",
  programs: "/programs",
  shippingAddresses: "/shippingAddresses",
  getOchAddresses: "/globalOchAddress",
  getAddressByLocation: "",
  saveShippingAddress: "/saveShippingAddress",
  updateShippingAddress: "/updateShippingAddress",
  deleteShippingAddress: "/deleteShippingAddress",
  users: "/users",
  userDetails: "/user-details",
  manageUser: "/manage_user",
  handshaking: "/handshaking",
  configlist: "/configlist",
  orderCancel: "/order-cancel",
};
