import { clearCacheData } from "./Utils";
import ssoConfigUrls from "../configs/UrlContants";

const CLIENT_ID = ssoConfigUrls.ssoClientId;
const ISSUER = ssoConfigUrls.ssoIssuer;

const REDIRECT_URI = window.location.origin + "/implicit/callback";
const POST_REDIRECT_URI = window.location.origin + "/";

// eslint-disable-next-line
export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: POST_REDIRECT_URI,
    scopes: ["openid", "pepappmcoeroles", "email", "profile"],
    responseType: "code",
    pkce: true,
    tokenManager: {
      autoRenew: true,
      storage: "sessionStorage",
    },
    onSessionExpired: function () {
      clearCacheData();
      window.location.assign("/");
    },
  },
};
